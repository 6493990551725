
import { Filter } from "@/cms-services/filter";
import { ListDataSource } from "@/data/List/ListDataSource";
import { Vue, Component } from "vue-property-decorator";
import editor from "./editor";
@Component({
  mixins: [editor],
})
export default class InineCountrySelector extends Vue {
  loaded: boolean = false;
  field!: any;
  value: any;
  listDataSource: ListDataSource = new ListDataSource({
    className: this.field.config.className,
    config: {
      pageSize: 1000,
    },
  });
  items:any;

  get caption() {
    return this.field.config?.labelKeyName ?? "caption";
  }

  get list(){
    return this.items ?? this.listDataSource.items
  }

  save: any;
  dataSource!: any;
  tempValue: any;

  async setNull() {
    this.value = null;
    await this.dataSource.updateField({
      fieldName: this.field.name,
      fieldValue: this.value,
    });

    this.value = this.tempValue;
  }

  get valueCaption() {
    const cb = (c: any) => +c.id === +this.value;
    const broadcast = this.listDataSource.items.find(cb);
    return broadcast[this.caption];
  }

  async created() {
    await this.listDataSource.get();
    this.loaded = true;
  }
}
